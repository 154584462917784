<template>
  <main class="color-primary h-100 cancellation-bg blank-aside-js" :class = "(isOpenAside=='true')?'blank-aside':''">
    <p class="main-title weight-700">
      {{ $t('CANCELLATION.CANCELLATION') }}
    </p>
    <div class="cancellation-main-content">
      <div class="d-flex justify-content-between">
        <div class="px-2 text-center">
          <label>{{ $t('CANCELLATION.TRANSACTION_NUMBER') }}</label>
          <p><b>2344</b></p>
        </div>
        <div class="px-2 text-center">
          <label>{{ $t('CANCELLATION.BUSINESS_NUMBER') }}</label>
          <p><b>78</b></p>
        </div>
      </div>
    </div>
    <v-container>
      <v-data-table
        :headers="headers"
        :items="tableData"
        class="cus-table editable-table main-table striped-table"
        item-key="id"
      >
      </v-data-table>
    </v-container>
    <div class="cancellation-main-content">  
      <div class="px-2 mt-4">
        <div class="px-2 mt-4">
          <label>{{ $t('CANCELLATION.CANCELLATION_AMOUNT') }}</label>
          <input type="text" class="input" v-model="transaction_number" />
        </div>
        <div class="px-2 mt-4">
          <label>{{ $t('CANCELLATION.PROVIDER_APPROVAL_NUNBER') }}</label>
          <input type="text" class="input" v-model="transaction_number" />
        </div>
        <div class="px-2 mt-4">
          <label>{{ $t('COMMON.COMMENTS') }}</label>
             <textarea
                rows="4"
                class="w-100 bg-white m-b-15 px10 bdbr-2"
                v-model="transaction_number"
              >
              </textarea>
        </div>
        <div class="px-2">
          <button class="primary-btn">
            {{ $t('SUPPLIER_PAYMENT.CHECK') }}
          </button>
        </div>
      </div>
    </div>
  </main>  
</template>

<script>
import { mapState, mapActions } from 'vuex'
export default {
  name: 'CancellationsTransaction',
  data() {
    return {
      headers: [
        {
          text: this.$t('REGISTER_RESELLER.BUSINESS_NAME'),
          align: 'center',
          sortable: false,
          value: 'supplier_id',
        },
        {
          text: this.$t('REPORT_SALES.MOBILE'),
          align: 'center',
          sortable: false,
          value: 'supplier_id',
        },
        {
          text: this.$t('REPORT_SALES.SUPPLIER_ID'),
          align: 'center',
          sortable: false,
          value: 'supplier_id',
        },
        {
          text: this.$t('GIFTCARD_PAYMENT_RESULT.PRODUCT'),
          align: 'center',
          sortable: false,
          value: 'supplier_id',
        },
        {
          text: this.$t('COMMON.DATE'),
          align: 'center',
          sortable: false,
          value: 'supplier_id',
        },
        {
          text: this.$t('COMMON.HOUR'),
          align: 'center',
          sortable: false,
          value: 'supplier_id',
        },
        {
          text: this.$t('REPORT_SALES.CONSUMER_PRICE'),
          align: 'center',
          sortable: false,
          value: 'supplier_id',
        },
        {
          text: this.$t('REPORT_SALES.COST_PRICE'),
          align: 'center',
          sortable: false,
          value: 'supplier_id',
        },
      ],
      tableData: [],
      transaction_number: "",
      business_number: ""
    }
  }
}
</script>

<style lang="scss" scoped>
.cancellation-main-content {
  max-width: 420px;
  margin: 10px auto;
}
</style>